.navbar2 {
    top: 6.0em;
    width: 100%;
    height: calc(var(--icon-size) * 1.1);
    background-color: var(--DatamachineTextboxGrey);
    padding: 0 1rem;
    border-bottom: var(--DatamachineBlue);
    /*display: flex;*/
    /*flex-direction: row;*/
    justify-content: space-between;
    position: fixed;
    padding-left: 2.0rem;
    z-index: 10;
}
.navbarNavLeft {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 1;
}
