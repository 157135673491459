.tablestyle {
    padding: unset;
    min-height: unset;
    text-align: unset;
}

.tablestyle table {

}

.tablestyle thead {

}

.tablestyle tbody {

}

.tablestyle tfoot {

}

.tablestyle table > tr {
}

.tablestyle col {
}

.tablestyle colgroup {
}

.tablestyle tr:nth-child(even) {
    background-color: var(--DatamachineDarkGrey);
    color: var(--DatamachineBackGroundGrey);
}

.tablestyle tr:nth-child(odd) {
    background-color: var(--DatamachineBackGroundGrey);

}

.tablestyle td, th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.8rem;
}

.tablestyle th {
    background-color: var(--DatamachineDarkBlue);
    color: var(--DatamachineTextboxGrey);
    height: 3.8rem;
    text-align: center;
}

.tablestyle caption {
}
