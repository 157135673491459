
.searchResults div:nth-child(2n+1) {
    background-color: var(--DatamachineTextboxGrey);
    color: var(--DatamachineTextColor);
}

.searchResults div {
    padding: 10px 10px 10px 10px;
}


.node {
    display: flex;
    /*border-radius: 2px;*/
    /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
    /*margin-bottom: 10px;*/
    /*background: darkgrey;*/
    /*border: 5px solid red;*/
}

.numberofhits{
    /*height: 2.0rem;*/
    padding-top: 0.6rem;
}

.box1 {
    background: darkgrey;
    padding: 15px;
}

.box2 {
    background: darkgrey;
    flex-wrap: wrap;
    padding: 5px;
}

.tableHeader {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    padding-right: 1.5rem;
    /*background: ghostwhite;*/
    /*align-items: flex-start;*/
    /*min-width: 40%;*/
}

.datatable {
    /*min-width: 100%;*/
    padding-bottom: 10px;
}

.datatable th {
    /*background-color: slategray;*/
    /*color: white;*/
    font-size: 1.4rem;
    font-weight: bold;
    padding-left: 1.0rem;
    padding-right: 0.5rem;
    white-space: nowrap;
}

.datatable td {
    padding-left: 1.0rem;
    padding-right: 0.5rem;
    font-size: 1.4rem;
}

.datatable table td:last-child {
    width: 100%;
}

.datatable tr:nth-child(odd) {
    background-color: lightgray;
    color: black;
}

.datatable tr {
    background-color: white;
    color: black;
}

.datatable .datatableHeader {
    background-color: #4A7DBF;
    color: white;
    font-weight: bolder;
    font-style: oblique;
}

.datatable .extraNode {
    display: none;
}
