.showimage {
    max-height: 150px;
    /*background-color: rgba(255, 255, 255, 0.5);*/
    paddings: 5px 5px 5px 35px;
}
.dialog {
    box-shadow: -8px 8px 6px -6px black;
    /*position: static;*/
    /*left: 20%;*/
    /*top: 10%;*/
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.image {
    max-height: 500px;
}
