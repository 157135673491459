/*body {*/
/*    margin: 0;*/
/*    background: yellow;*/
/*    font-family: roboto;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/


:root {
    /*--bg: #242526;*/
    /*--bg-accent: #484a4d;*/
    /*--text-color: #dadce1;*/
    --button-size: 60px;
    /*--border: 1px solid #474a4d;*/
    --border-radius: 8px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*a {*/
/*    color: var(--text-color);*/
/*    text-decoration: none;;*/
/*}*/

/* Top Navigation Bar */

/* <nav> */
.navbar {
    top: 0;
    width: 100%;
    height: var(--button-size);
    background-color: var(--DatamachineDarkBlue);
    padding: 0 1rem;
    border-bottom: 1px solid var(--DatamachineBlue);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
}


/* <ul> */
.navbarNav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
}

.navbarNavLeft {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 2;
}

.navbarNavLeft img {
    /*fill: var(--DatamachineOrange);*/
    width: 60%;
    height: 100%;
    z-index: 2;

    /*height: 20px;*/
}

/* <li> */
.navItem {
    width: calc(var(--button-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.navItem a {
    color: var(--DatamachineOrange);
    text-decoration: none;
    z-index: 2;
}

/* Icon Button */
.iconButton {
    --button-size: calc(var(--button-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--DatamachineGreenBlue);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    z-index: 2;
}


.iconButton :hover {
    filter: brightness(1.2);
    z-index: 2;
}

.iconButton svg {
    fill: var(--DatamachineDarkBlue);
    width: 20px;
    height: 20px;
    z-index: 2;
}


/* Dropdown Menu */

.dropdown {
    position: fixed;
    top: 58px;
    width: 36rem;
    transform: translateX(-45%);
    background-color: var(--DatamachineDarkBlue);
    border: var(--DatamachineBlue);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    z-index: 2;
}

.menu {
    width: 100%;
    z-index: 2;
}

.menuItem {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
    font-size: 1.4rem;
    transform: translateY(-40%);
    z-index: 2;
}

.menuItem .icon-button {
    margin-right: 0.5rem;
    z-index: 2;
}


.menuItem .icon-button:hover {
    filter: none;
    z-index: 2;
}

.menuItem:hover {
    background-color: var(--DatamachineBlue);
    z-index: 2;
}

.iconRight {
    --button-size: calc(var(--icon-size) * 0.2);
    width: var(--button-size);
    height: var(--button-size);
    margin-left: auto;
    transform: translateY(-80%);
    z-index: 2;
}

