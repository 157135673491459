.iconButton {
    --iconbutton-size: calc(var(--icon-size) * 1.5);
    width: var(--iconbutton-size);
    height: var(--iconbutton-size);
    background-color: var(--DatamachineBackGroundGrey);
    fill: var(--DatamachineDarkBlue);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}


.iconButton :hover {
    /*filter: invert(100%);*/
    fill: var(--DatamachineBlue);
}

.iconButton svg {
    width: var(--icon-size);
    height: var(--icon-size);

}

.suggestionFiller {
    --suggestioniconbutton-size: calc(var(--icon-size) * 0.9);
    width: var(--suggestioniconbutton-size);
    height: var(--suggestioniconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    /*fill: var(--DatamachineDarkBlue);*/
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*vertical-align:middle;*/
    transition: filter 300ms;
}


.suggestionButton {
    --suggestioniconbutton-size: calc(var(--icon-size) * 0.9);
    width: var(--suggestioniconbutton-size);
    height: var(--suggestioniconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    fill: var(--DatamachineDarkBlue);
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*vertical-align:middle;*/
    transition: filter 300ms;
}

.suggestionButton :hover {
    /*filter: brightness(1.2);*/
    /*filter: invert(100%);*/
    /*fill: var(--DatamachineBlue);*/
    background-color: var(--DatamachineGreenBlue);
    cursor: pointer;
}

.suggestionButton svg {
    width: var(--suggestioniconbutton-size);
    height: var(--suggestioniconbutton-size);

}


.suggestionButtonActive {
    --suggestioniconbutton-size: calc(var(--icon-size) * 0.9);
    width: var(--suggestioniconbutton-size);
    height: var(--suggestioniconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    fill: var(--DatamachineOrange);
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.suggestionButtonActive :hover {
    /*filter: brightness(1.2);*/
    /*filter: invert(100%);*/
    /*fill: var(--DatamachineBlue);*/
    background-color: var(--DatamachineGreenBlue);
}

.suggestionButtonActive svg {
    width: var(--suggestioniconbutton-size);
    height: var(--suggestioniconbutton-size);

}

/*.smallButton {*/
/*    --iconbutton-size: calc(var(--icon-size) * 0.5);*/
/*    width: var(--iconbutton-size);*/
/*    height: var(--iconbutton-size);*/
/*    background-color:  red;*/
/*    fill: var(--DatamachineDarkBlue);*/
/*    border-radius: 5%;*/
/*    padding: 1px;*/
/*    margin: 2px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    !*vertical-align:middle;*!*/
/*    transition: filter 300ms;*/
/*}*/

.smallButton svg {
    --smalliconsmallbutton-size: calc(var(--icon-size) * 0.5);
    width: var(--smalliconsmallbutton-size);
    height: var(--smalliconsmallbutton-size);
}

.normalButton {
    --normaliconbutton-size: calc(var(--icon-size) * 1.8);
    width: var(--normaliconbutton-size);
    height: var(--normaliconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    fill: var(--DatamachineDarkBlue);
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*vertical-align:middle;*/
    transition: filter 300ms;
}

.normalButton :hover {
    /*filter: brightness(1.2);*/
    /*filter: invert(100%);*/
    /*fill: var(--DatamachineBlue);*/
    background-color: var(--DatamachineGreenBlue);
}

.normalButton svg {
    width: var(--normaliconbutton-size);
    height: var(--normaliconbutton-size);

}


.normalButtonActive {
    --normaliconbutton-size: calc(var(--icon-size) * 1.8);
    width: var(--normaliconbutton-size);
    height: var(--normaliconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    fill: var(--DatamachineOrange);
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.normalButtonActive :hover {
    /*filter: brightness(1.2);*/
    /*filter: invert(100%);*/
    /*fill: var(--DatamachineBlue);*/
    background-color: var(--DatamachineGreenBlue);
}

.normalButtonActive svg {
    width: calc(var(--icon-size) * 2.0);
    height: calc(var(--icon-size) * 2.0);

}

.mediumButton {
    --mediumiconbutton-size: calc(var(--icon-size) * 4.0);
    width: var(--mediumiconbutton-size);
    height: var(--mediumiconbutton-size);
    /*background-color:  var(--DatamachineBackGroundGrey);*/
    fill: var(--DatamachineDarkBlue);
    border-radius: 5%;
    padding: 1px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*vertical-align:middle;*/
    transition: filter 300ms;
}

.mediumButton :hover {
    /*filter: brightness(1.2);*/
    /*filter: invert(100%);*/
    /*fill: var(--DatamachineBlue);*/
    background-color: var(--DatamachineGreenBlue);
}

.mediumButton svg {
    width: var(--mediumiconbutton-size);
    height: var(--mediumiconbutton-size);

}



div.showInline {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

p.showPInline {
    display: inline-flex;
    flex-direction: row;
}
