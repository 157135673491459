* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

:root {
    /*--DatamachineBlue: #4A7DBF;*/
    --DatamachineBlue: #283d85;
    --DatamachineOrange: #FFBE54;
    --DatamachineRed: #8E384E;
    --DatamachineGreyBlue: #83A5BC;
    --DatamachineDarkBlue: #16224A;
    --DatamachineGreenBlue: #37BEB7;
    --DatamachineBackGroundGrey: #F9F9F6;
    --DatamachineTextboxGrey: #D6D7CD;
    --DatamachineTextLogoGrey: #B7B7AA;
    --DatamachineDarkGrey: #515457;
    --iconbackgroundcolor: lightgray;
    --iconbackgroundcolor-accent: blue;
    --DatamachineTextColor: var(--DatamachineDarkBlue);
    --text-color: red;
    --icon-size: 30px;
    --iconborder: 1px solid var(--DatamachineDarkGrey);
    --iconborder-radius: 8px;
    --iconspeed: 500ms;
}

html {
    font-size: 62.5%;
}

label {
    display: block;
    margin-top: 10px;
}

/*html, body, #root, #root>div{*/
/*  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);*/
/*}*/

body {
    /*display: flex;*/
    /*background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);*/
    background: var(--DatamachineBackGroundGrey);
    /*background: url(https://www.datamachine.nl/uploads/images/datamachineBackground.svg);*/
    /*font-size: 16px;*/
    font-weight: 400;
    line-height: 160%;
    font-family: 'Raleway', sans-serif;
    /*-webkit-font-smoothing: antialiased; !* Fix for webkit rendering *!*/
    /*-webkit-text-size-adjust: 100%;*/
    text-size-adjust: 100%;
    color: var(--DatamachineDarkGrey);
}


h1,
h2,
h3,
h4,
h5,
h6,
legend {
    font-weight: 700;
    /*  font-family: 'Raleway', Arial, Helvetica, sans-serif;*/
    font-family: 'Raleway', sans-serif;
    line-height: 150%;
    color: #333;
    letter-spacing: 1px;
}

h1 {
    font-size: 4.8rem;
    text-transform: uppercase;
}

h2 {
    font-size: 3.6rem;
    text-transform: uppercase;
}

h3 {
    font-size: 3.0rem;
    text-transform: uppercase;
}

h4 {
    font-size: 2.4rem;
    font-weight: 600;
}

h5 {
    font-size: 2.0rem;
    font-weight: 600;
}

h6 {
    font-size: 1.6rem;
    font-weight: 600;
}

p {
    font-size: 1.6rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-bottom: 0px;
}


small {
    font-family: 'Lato', sans-serif;
}

a {
    margin: 6px;
    padding: 0px;
    color: var(--DatamachineDarkBlue);
    /*text-decoration: none;*/
}

a:hover {
    color: var(--DatamachineDarkBlue);
    text-decoration: none;
}

a[href='#'] {
    /*margin: 5px;*/
    /*display: block;*/
    text-decoration: none;
}

table {

}

th {
    font-size: 1.6rem;
    font-weight: 600;
}

tr {
    font-size: 1.6rem;
}


.feature-content h3 {
    margin: 14px 0;
}

.subtitle + h3 {
    margin: 0 0 14px 0;
}

div.headDiv {
    margin-top: 6.0rem;
    padding: 2.0rem;
    /*width: 100vh;*/
    max-height: 100vh;
}

div.headDivToolbar {
    margin-top: 8.0rem;
    padding: 4.0rem;
    max-width: 1200px;
}


.card-container.card {
    /*max-width: 350px !important;*/
    max-width: 350px;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /*-moz-border-radius: 2px;*/
    /*-webkit-border-radius: 2px;*/
    border-radius: 2px;
    /*-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
    /*-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    /*-moz-border-radius: 50%;*/
    /*-webkit-border-radius: 50%;*/
    border-radius: 50%;
}

/*Auto suggest*/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    border: 1px solid var(--DatamachineDarkBlue);
    border-radius: 5px;
    font-size: 1.6rem;
    height: 3.0rem;
    width: 50rem;
    padding: 2px 10px 2px 20px;
    outline: 0;
    z-index: 0;
    background-color: var(--DatamachineGreenBlue);



    /*width: 50rem;*/
    /*height: 2.4rem;*/
    /*padding: 2px 10px 2px 20px;*/
    /*font-family: 'Open Sans', sans-serif;*/
    /*font-weight: 300;*/
    /*font-size: 1.4rem;*/
    /*border: 1px solid #aaa;*/
    /*border-radius: 4px;*/
    /*-webkit-appearance: none;*/
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 31px;
    /*top: 51px;*/
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /*z-index: 1;*/
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 1.2rem;
    color: #777;
}

/* CSSTransition classes Menu */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--iconspeed) ease;
}

.menu-primary-exit {
    position: absolute;
}

.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--iconspeed) ease;
}


.menu-secondary-enter {
    transform: translateX(110%);
}

.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--iconspeed) ease;
}

.menu-secondary-exit {

}

.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--iconspeed) ease;
}

.firstdiv {
    padding: 0rem 1.5rem 1.5rem 1.5rem;
}

.search {
    border: 1px solid var(--DatamachineDarkBlue);
    border-radius: 5px;
    font-size: 1.6rem;
    height: 3.0rem;
    width: 50rem;
    padding: 2px 10px 2px 20px;
    outline: 0;
    background-color: var(--DatamachineGreenBlue);
    z-index: 0;
}

p.searchParagraph {
    padding: 2px 10px 2px 20px;

}

.seperatorBanner{
    width: 100%;
    background-color: var(--DatamachineGreenBlue);
    padding: 0.6rem 1rem;
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    align-items:center;
    color: var(--DatamachineDarkBlue);
    margin-bottom: 0px;
}

img.bg {
    /* Set rules to fill background */
    z-index: -1;
    /*min-height: 80vh;*/
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 120%;
    height: 150vh;
    /* Set up positioning */
    position: fixed;
    /*top: -150px;*/
    /*left: -150px;*/
    overflow: hidden
}



