.wrapper {
    /*overflow:auto;*/
    /*display: flex;*/
    /*justify-content: flex-start;*/
    /*align-items: flex-start;*/
    padding: 1.5em;
    /*flex-direction: row;*/
    /*border: 5px dotted greenyellow;*/
}


.node {
    /*display: flex;*/

    /*border-radius: 2px;*/
    /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
    /*margin-bottom: 10px;*/
    background: var(--DatamachineTextboxGrey)
    /*padding: 10px 0px 10px 20px;*/
    /*border: 5px solid green;*/
}

.node:nth-child(2n) {
    background: white;
}

p.node {
    margin-bottom: 0px;
}

.first {
    /*float:left;*/
    padding: 10px;
    /*border: 5px solid red;*/

}

.alert {
    color: red;
}

div.node > div:nth-of-type(1) {
    background: #2a2a2a;
    color: darkgrey;
    /*font-weight: bold;*/
}

.second {
    /*float:left;*/
    display: flex;
    /*border-radius: 2px;*/
    /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
    /*margin-bottom: 10px;*/
    /*background: darkgrey;*/


    padding: 10px 25px 10px 25px;
    background-color: #2a2a2a;
    color: #a9a9a9;
}

.box1 {
    /*background: darkgrey;*/
    padding: 5px;
}

.box2 {
    /*background: darkgrey;*/
    flex-wrap: wrap;
    padding: 5px;
}

.whiteText {
    color: white;
    font-weight: 600;
}

.clickable {
    cursor: pointer;
}

div.showInlineType {
    display: inline-flex;
    padding-left: 1.0rem;
    flex-direction: row;
    align-items: center;
    background: var(--DatamachineDarkBlue);
    color: var(--DatamachineTextboxGrey);
    font-size: 1.6rem;
    vertical-align: middle;
    max-resolution: 0px;
    height: 3.0rem;
    margin-bottom: 0px;
    width: 100%;
}

p.showInlineType {
    font-size: 1.6rem;
}

.showInlineProperty {
    font-size: unset;
}

div.showInlineProperty {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    /*background: var(--DatamachineDarkBlue);*/
    /*color: var(--DatamachineTextboxGrey);*/
    font-size: 1.6rem;
    vertical-align: middle;
    padding: 1.0rem;
    /*min-height: 0.5rem;*/
    /*height: 3.0rem;*/
    min-width: 15rem;
    margin-bottom: 0px;
    width: 100%;

}

span.showInlineProperty {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    /*background: var(--DatamachineDarkBlue);*/
    /*color: var(--DatamachineTextboxGrey);*/
    /*font-size: 1.6rem;*/
    vertical-align: middle;
    padding: 1.0rem;
    /*min-height: 0.5rem;*/
    /*height: 3.0rem;*/
    min-width: 15rem;
    /*margin-bottom: 0px;*/
    width: 100%;
}

p.showInlineProperty {
    margin-bottom: 0px;
    /*font-size: 1.6rem;*/
}

.editInline {
    font-size: 1.4rem;
}

p.editUserInline{
    width: 20rem;
    font-size: 1.4rem;
}
p.editUserInputInline{
    width: 60rem;
    font-size: 1.4rem;
}

.parent{
    /*border:1px solid red;*/
    display:flex;
    /*flex-wrap: wrap;*/
    justify-content:flex-start;
    align-self: center;
    font-size: 1.4rem;
    padding:1.0rem 0rem;
}

.child{
    align-self: center;
    /*border:1px dashed green;*/
}

.child:first-child{
    flex:0 0 20rem;
    /*border:1px dashed green;*/
}

.child:last-child{
    flex: 0 0 60rem;
    /*flex-basis: 100%;*/
    /*border:1px dashed blue;*/
}

input[type=text].child {
    padding: unset;
    margin-top: unset;
    width: 100%;
    font-size: 1.4rem;
    /*padding: 0rem;*/
    /*margin: 0;*/
    /*box-sizing: border-box;*/
}

p.fieldname {
    min-width: 20rem;
}
