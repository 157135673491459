.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}
.editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    font-size: 1.6rem;
}

.editor-class a {
    color: hotpink;
}
.toolbar-class {
    border: 1px solid #ccc;
}